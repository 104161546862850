import React from "react";
import { FaFacebook, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlideshare,
  faFlickr,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row my-5">
          <div className="col-sm-4">
            <div className="fs-3 text-primary text-start">
              <strong>Our Innovation</strong> for successful agriculture
            </div>
            <div className="mt-2">
              <div className="d-flex justify-content-start">
                <a
                  href="https://www.linkedin.com/company/basf/?originalSubdomain=it"
                  title="Linkedin"
                  target="_blank"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FaLinkedin size={30} />
                </a>
                <a
                  href="https://www.facebook.com/BASF-Agricultural-Solutions-1741459832625091/"
                  target="_blank"
                  title="Facebook"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FaFacebook size={30} />
                </a>

                <a
                  href="https://twitter.com/basf_it"
                  title="Twitter"
                  target="_blank"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="28.079"
                    viewBox="0 0 30 28.079">
                    <g id="layer1" transform="translate(-281.5 -167.31)">
                      <path
                        id="path1009"
                        d="M281.573,167.31,293.156,182.8,281.5,195.389h2.623l10.2-11.024,8.245,11.024H311.5l-12.234-16.358,10.849-11.721h-2.623l-9.4,10.153L290.5,167.31Zm3.858,1.932h4.1l18.11,24.214h-4.1Z"
                        transform="translate(0 0)"
                        fill="#65ac1e"
                      />
                    </g>
                  </svg>
                </a>
                <a
                  href="https://youtube.com/playlist?list=PL6qwtP-pW0yTjVhFPDJ164KR3ot2_zgqy"
                  target="_blank"
                  title="YouTube"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FaYoutube size={30} />
                </a>
                <a
                  href="https://www.slideshare.net/basf"
                  target="_blank"
                  title="Slideshare"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FontAwesomeIcon icon={faSlideshare} size="lg" />
                </a>
                <a
                  href="https://www.flickr.com/photos/basf/"
                  target="_blank"
                  title="flickr"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FontAwesomeIcon icon={faFlickr} size="lg" />
                </a>
                <a
                  href="https://www.instagram.com/basf_global/?hl=it"
                  target="_blank"
                  title="Instagram"
                  
                  className="p-1 social-icon me-2 pt-2">
                  <FontAwesomeIcon icon={faInstagram} size="lg" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-3 mt-4 mt-sm-0">
            <div className="text-dark footer-head">
              <h5>
                <strong className="fs-6">Company</strong>
              </h5>
            </div>
            <ul className="list-unstyled footer-list mt-2 g-2">
              <li className="my-2">
                <a
                  href="https://www.basf.com/it/it.html"
                  title="basf.com"
                  target="_blank"
                  className="text-dark">
                  basf.com
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.basf.com/global/en/careers.html"
                  title="Career"
                  target="_blank"
                  className="text-dark">
                  Career
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.basf.com/global/en/media.html"
                  title="Media"
                  target="_blank"
                  className="text-dark">
                  Media
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.basf.com/global/en/who-we-are/sustainability.html"
                  title="Sustainability"
                  target="_blank"
                  className="text-dark">
                  Sustainability
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-3">
            <div className="text-dark footer-head">
              <h5>
                <strong className="fs-6">Informazioni generali</strong>
              </h5>
            </div>
            <ul className="list-unstyled footer-list mt-2">
            <li className="my-2">
                <a
                  href="https://www.agrigenius.it/ape/s/?language=it"
                  title="Agrigenius®"
                  target="_blank"
                  className="text-dark">
                  Agrigenius<sup className="fs-7">®</sup>
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.agro.basf.it/it/Chi-siamo.html"
                  title="Chi siamo"
                  target="_blank"
                  className="text-dark">
                  Chi siamo
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.agro.basf.it/it/Dove-siamo.html"
                  title="Dove siamo"
                  target="_blank"
                  className="text-dark">
                  Dove siamo
                </a>
              </li>
              <li className="my-2">
                <a
                  href="https://www.basf.com/it/it/legal/data-protection-at-basf.html#accordion_v2-f838f16a59-item-26961bd037"
                  title="Privacy policy"
                  target="_blank"
                  className="text-dark">
                  Privacy policy
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer footer-bar pb-0 mb-0 pb-sm-5 mb-2">
        <div className="container text-center">
          <div className="row align-items-center">
            <div className="col-sm-4">
              <div className="text-sm-start">
                <p className="mb-0">
                  <strong>Copyright © BASF SE 2025</strong>
                </p>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="text-sm-end">
                <p className="mb-0">
                  
                  <a
                    href="https://www.agro.basf.it/it/Crediti.html"
                    className="text-dark mx-2"
                    target="_blank"
                    title="Crediti">
                    Crediti
                  </a>{" "}
                  {" "}|
                  <a
                    href="/trattamento-dati"
                    className="text-dark mx-2"
                    target="_blank"
                    title="Trattamento dei dati">
                    Trattamento dei dati
                  </a>{" "}
                  {" "}
                  |
                  <a
                    href="/cookie-policy"
                    className="text-dark mx-2"
                    target="_blank"
                    title="Cookies">
                    Cookies
                  </a>{" "}
                  {" "}
                 
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
