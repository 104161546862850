import React, { useState, useEffect } from 'react';
import Form from "./Form";
import SEO from "./Seo";
import CookieBanner from "./Cookies";
import BackToTopButton from "./BackToTopButton";
import Header from "./Header";
import BgVite from "./assets/img/bg-vite.webp";
import AgrigeniusVisual from "./assets/img/agrigenius-visual.webp";
import Dss from "./assets/img/dss.webp";
import SistemaDigitale from "./assets/img/sistema-digitale.webp";
import Icon1 from "./assets/img/icone/1.png";
import Icon2 from "./assets/img/icone/2.png";
import Icon3 from "./assets/img/icone/3.png";
import Icon4 from "./assets/img/icone/4.png";
import Icon5 from "./assets/img/icone/5.png";
import Icon6 from "./assets/img/icone/6.png";
import Logo from "./assets/img/logo.svg";
import { BiCheckCircle } from "react-icons/bi";
import jarallax from "jarallax/dist/jarallax.min";
import MobileButton from "./MobileButton";
import DesktopButton from "./DesktopButton";

const HomeSection = () => {
  const [uid, setUid] = useState("");  // Stato per l'UID

  useEffect(() => {
    // Inizializza l'effetto parallasse
    jarallax(document.querySelectorAll(".parallax"), {
      speed: 0.5,
    });
  }, []);

  return (
    <>
      <SEO
        title="Agrigenius® Vite | La tua cassetta degli attrezzi digitale"
        description="Il lavoro del viticoltore è sempre più complesso. Scopri Agrigenius® Vite, il sistema di supporto alle decisioni pensato per gestire il vigneto"
      />
      <Header customClasses="bg-primary" logoUrl={require("./assets/img/logo.svg").default} />
      <section
        className="pt-150 mb-5 d-table w-100 parallax z-1"
        style={{ background: `url(${BgVite}) center center` }}
        id="richiedi-preventivo"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-7 col-md-6 order-1 order-md-1">
              <div className="title-heading">
                <h1 className="heading title-dark text-white mb-3 lh-1">
                  DSS e Agricoltura: una rivoluzione per la gestione e il profitto
                </h1>
                <p className="para-dark text-light h4 fw-light">
                  L’agricoltura è un settore antico, ma mai come oggi è costretto a guardare al futuro.
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-2">
              <div className="card d-none d-sm-block shadow rounded border-0 me-lg-3 mb-form">
                <div className="card-header bg-secondary-green no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                  <Form
                    buttonClasses="btn-primary"
                    landingPageId="cassetta attrezzi"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas009;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    trackingCode={`https://bqlgw3bxrpqrxgwqvdydj7xj2u0bqbtj.lambda-url.eu-central-1.on.aws/?uid=${uid}&page=basf2025_dss`}
                    uid={uid}  // Passa l'UID come prop
                    setUid={setUid}  // Passa la funzione per aggiornare l'UID
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section pt-0 pb-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <p>
                Per questo, i <strong>Sistemi di Supporto Decisionale (DSS)</strong> stanno emergendo come <strong>strumenti
                fondamentali per ottimizzare le operazioni e aumentare i profitti.</strong>
                In particolare, <strong>Agrigenius<sup className="fs-7">®</sup></strong> si distingue <strong>come una delle piattaforme più efficienti per la gestione e il monitoraggio dei campi.</strong>
                &nbsp;Ma cosa rende questo sistema così rivoluzionario? Scopriamolo insieme.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-3 pb-3 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <p className="text-white mb-0">
                <strong>Ritorni misurabili sugli investimenti (ROI): il valore di una decisione informata!</strong>{" "}
              </p>
              <p className="text-white mb-0">
                 Una delle maggiori sfide dell’agricoltura moderna è il bilanciamento tra investimento e
                rendimento. <strong>Agrigenius<sup>®</sup></strong> aiuta gli agricoltori a prendere decisioni basate su
                dati precisi, riducendo l'incertezza e migliorando i risultati.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-1 pt-sm-3 pb-1 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <img
                className="img-fluid"
                width="451"
                height="259"
                loading="lazy"
                src={Dss}
                alt="Dss"
              />
            </div>
            <div className="col-lg-7 col-md-7 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Perché scegliere <strong>Agrigenius<sup>®</sup></strong> Vite?
                </div>
                <p>
                  Grazie all’analisi avanzata di clima, suolo, acqua, esigenze delle varietà e fasi fenologiche, permette di <strong>pianificare in modo strategico</strong> gli interventi; <strong>ridurre gli sprechi</strong>, ottimizzando carburante, acqua ed energia; <strong>prevenire i rischi</strong>, grazie a previsioni accurate e alert tempestivi.
                </p>
              </div>
            </div>
            <div className="col-lg-12 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Riduzione dei costi operativi: meno risorse, più efficienza
                </div>
                <p><strong>I costi operativi</strong> rappresentano una delle <strong>voci più pesanti nel
                  bilancio</strong> di ogni azienda agricola. <strong>Agrigenius<sup>®</sup></strong> aiuta a ridurre le
                  spese grazie a:</p>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Supporto nella gestione della cultura</strong>: permette di conoscere come e quando intervenire in campo con trattamenti agrofarmaci, fertilizzanti e irrigazioni,  con una valutazione per fase fenologica e specifica per più di 120 varietà di vite da vino;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Monitoraggio in tempo reale</strong>: sensori e tecnologie IoT integrati nel DSS forniscono dati costanti per assicurare un intervento sulle culture mirato ed efficace;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Ottimizzazione delle risorse umane</strong>: con una pianificazione più precisa, è possibile migliorare la produttività del team.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0 pt-sm-0 pb-0 bg-primary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <img
                className="img-fluid"
                width="382"
                height="203"
                loading="lazy"
                src={SistemaDigitale}
                alt="Sistema Digitale"
              />
            </div>
            <div className="col-lg-8 col-md-8 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="text-white ms-lg-2">
                <p className="mb-2 mb-sm-0">
                  Nato dall’unione tra le <strong>competenze di BASF</strong> e{" "}
                  <strong>l’esperienza di Hort@</strong>, Agrigenius
                  <sup className="fs-7">®</sup> Vite è il sistema digitale di
                  supporto alle decisioni che permette a tecnici e viticoltori
                  di <strong>monitorare lo stato di salute del vigneto</strong>{" "}
                  e di <strong>prendere decisioni tempestive</strong>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-white">
        <div className="container px-2 px-sm-0">
          <div className="row">
            <div className="col-12">
              <div className="section-title ms-lg-4 mt-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Agrigenius<sup>®</sup> Vite ti permette di:
                </div>
              </div>
            </div>
          </div>
          <div className="row g-0 align-items-stretch">
            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon6}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Programmare gli interventi</strong> in base allo{" "}
                    <strong>sviluppo fenologico</strong> della pianta
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon5}
                    loading="lazy"
                    alt="Valutazione rischi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    Effettuare una{" "}
                    <strong>corretta valutazione dei rischi</strong> e
                    migliorare <strong>efficacia</strong> della strategia di
                    difesa
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon4}
                    loading="lazy"
                    alt="Registrare operazioni"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Registrare tutte le operazioni</strong> effettuate
                    in campo
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon3}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Programmare interventi</strong> irrigui e fertirigui
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon2}
                    loading="lazy"
                    alt="Ricevere allerte"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Ricevere allerte</strong> su potenziali danni da
                    gelo o alte temperature
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon1}
                    loading="lazy"
                    alt="Impostare avvisi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    Impostare <strong>avvisi di rischio infezione</strong> o{" "}
                    <strong>evoluzione insetti</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-2 pb-2 d-table w-100 parallax"
        style={{
          background: `url(${AgrigeniusVisual}) center center`,
          zIndex: 1,
        }}
        id="home"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay-white"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-12 col-md-12 order-1 order-md-1">
              <div className="title-heading mt-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Ottimizzazione delle risorse: una gestione sostenibile
                </div>
                <p className="para-dark">
                  La <strong>sostenibilità</strong> rappresenta non solo un imperativo etico, ma anche una concreta <strong>opportunità economica</strong>. Agrigenius<sup>®</sup> offre agli agricoltori un supporto avanzato per <strong>ottimizzare l’utilizzo delle risorse</strong> naturali, <strong>ridurre l’impatto</strong> ambientale e <strong>generare valore a lungo termine</strong> per le coltivazioni. Tra i principali vantaggi offerti da Agrigenius<sup>®</sup>, troviamo:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Gestione idrica intelligente</strong>
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Uso mirato degli agrofarmaci</strong>
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>Valorizzazione del suolo</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title mb-2 h4 text-secondary-green">
                Su misura per le tue esigenze
              </div>
              <p className="para-dark">
                Agrigenius<sup className="fs-7">®</sup> Vite è{" "}
                <strong>disponibile in diverse versioni</strong>, per rispondere
                alle necessità e agli obiettivi di grandi aziende e piccoli
                viticoltori.
              </p>
            </div>
            <div className="col-12">
              <div className="d-flex bg-secondary-green position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-secondary-green border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    Agrigenius Vite PRO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>La tecnologia incontra l'esperienza tecnica</strong>
                  </p>
                  <p className="mb-0 lh-sm">
                    Una piattaforma web completa per la gestione professionale
                    del tuo vigneto a 360°. Rielabora dati ambientali raccolti
                    tramite stazione meteo in campo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-primary position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-primary border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    Agrigenius Vite GO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      Per una visione semplice, tascabile ed immediata
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                    App mobile per smartphone e tablet, dedicata alla difesa del
                    vigneto. Riassume tutte le informazioni necessarie in modo
                    intuitivo, si collega ad un punto meteo virtuale.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-secondary-green position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-secondary-green border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <img
                    className="img-fluid pb-2 mb-2 border-bottom"
                    width="83"
                    height="30"
                    src={Logo}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                  <p className="fs-7 mb-0 text-white text-center lh-sm">
                    Agrigenius Vite TECH
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      La soluzione per chi gestisce più realtà agricole
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                    Una piattaforma web che consente di monitorare più realtà
                    produttive dislocate in areali lontani o con caratteristiche
                    molto diverse tra loro.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5" id="form-mobile">
              <div className="card d-sm-none d-block shadow rounded border-0 me-lg-3">
                <div className="card-header bg-secondary-green no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                  <Form
                    buttonClasses="btn-primary"
                    landingPageId="cassetta attrezzi"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas009;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    trackingCode={`https://bqlgw3bxrpqrxgwqvdydj7xj2u0bqbtj.lambda-url.eu-central-1.on.aws/?uid=${uid}&page=basf2025_dss`}
                    uid={uid}  // Passa l'UID come prop
                    setUid={setUid}  // Passa la funzione per aggiornare l'UID
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileButton buttonClasses="btn-primary" />
      <DesktopButton buttonClasses="btn-primary" />
      <BackToTopButton linkClasses="btn-primary" />
      <CookieBanner buttonVariant="primary" />
    </>
  );
};

export default HomeSection;
