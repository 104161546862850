import React, {useEffect, useState} from "react";
import Form from "./Form";
import SEO from "./Seo";
import CookieBanner from "./Cookies";
import BackToTopButton from "./BackToTopButton";
import BgVite from "./assets/img/bg-meteo.webp";
import AgrigeniusVisual from "./assets/img/agrigenius-visual.webp";
import Dss from "./assets/img/dss.webp";
import SistemaDigitale from "./assets/img/sistema-digitale.webp";
import Icon1 from "./assets/img/icone/1.png";
import Icon2 from "./assets/img/icone/2.png";
import Icon3 from "./assets/img/icone/3.png";
import Icon4 from "./assets/img/icone/4.png";
import Icon5 from "./assets/img/icone/5.png";
import Icon6 from "./assets/img/icone/6.png";
import { BiCheckCircle } from "react-icons/bi";
import jarallax from "jarallax/dist/jarallax.min";
import MobileButton from "./MobileButton";
import DesktopButton from "./DesktopButton";

const HomeSection = () => {
  const [uid, setUid] = useState("");  // Stato per l'UID

  useEffect(() => {
    // Inizializza l'effetto parallasse
    jarallax(document.querySelectorAll(".parallax"), {
      speed: 0.5,
    });
  }, []);
  return (
    <>
      <SEO
        title="Agrigenius® Vite | Il tuo sistema di allerta meteo"
        description="Grazie ad Agrigenius® Vite puoi conoscere le reali condizioni del vigneto in relazione al meteo e intervenire in modo tempestivo"
      />
 
      <section
        className="pt-150 mb-5 d-table w-100 parallax z-1"
        style={{ background: `url(${BgVite}) center center` }}
        id="richiedi-preventivo"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-7 col-md-6 order-1 order-md-1">
              <div className="title-heading">
                <h1 className="heading title-dark text-white mb-3 lh-1">
                Il sistema di allerta meteo per mettere al riparo il tuo vigneto
                </h1>
                <p className="para-dark text-light h4 fw-light">
                È possibile affidarsi a modelli previsionali per ottimizzare la gestione del vigneto mantenendo qualità e rese elevate? 
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-2">
              <div 
                className="card d-none d-sm-block shadow rounded border-0 me-lg-3 mb-form">
                <div className="card-header bg-secondary-green no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                  <Form
                    buttonClasses="btn-primary"
                    landingPageId="meteo"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas008;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    trackingCode={`https://bqlgw3bxrpqrxgwqvdydj7xj2u0bqbtj.lambda-url.eu-central-1.on.aws/?uid=${uid}&page=basf2025_hp`}
                    uid={uid}  // Passa l'UID come prop
                    setUid={setUid}  // Passa la funzione per aggiornare l'UID
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="section pt-0 pb-4 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6">
              <p>
              La coltivazione dell’uva da vino è particolarmente <strong>influenzata dalle condizioni meteo. Precipitazioni, temperatura e umidità</strong> influiscono sulla salute della pianta e sullo sviluppo di malattie, con un impatto sulla qualità e la resa delle produzioni. Inoltre, negli ultimi anni, si assiste con maggiore frequenza a <strong>eventi atmosferici sempre più imprevedibili e di forte intensità</strong>, con alternanza di temperature elevate e forte siccità con periodi di piogge intense.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-3 pb-3 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <p className="text-white mb-0">
              Esiste uno strumento digitale che permette a tecnici e viticoltori di conoscere le <strong>reali condizioni del vigneto in relazione al meteo</strong>, con il dettaglio di precipitazioni, temperatura, umidità relativa e stato della foglia, aiutandoli a pianificare in modo razionale i <strong>trattamenti, intervenire in modo tempestivo</strong>, se necessario, e prevenire il rischio di <strong>dilavamento</strong>. 
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-1 pt-sm-3 pb-1 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-5">
              <img
                className="img-fluid"
                width="451"
                height="259"
                loading="lazy"
                src={Dss}
                alt="Dss"
              />
            </div>
            <div className="col-lg-7 col-md-7 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-secondary-green">
                  La soluzione? Agrigenius<sup>®</sup> Vite
                </div>
                <p>
                Un <strong>sistema di supporto alle decisioni (DSS)</strong> che analizza i dati raccolti da <strong>stazioni agrometeorologiche</strong> e <strong>dati meteo previsionali</strong> con sofisticati <strong>modelli di sviluppo fenologico</strong> della pianta per fornire <strong>alert dedicati alle criticità</strong> e <strong>informazioni utili</strong> alla <strong>gestione del vigneto</strong>, al fine di attuare interventi tempestivi.
                </p>
              </div>
            </div>
            <div className="col-lg-12 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="section-title ms-lg-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Agrigenius<sup>®</sup> Vite è in grado di:
                </div>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    mostrare i <strong>dati sulla situazione meteo</strong> attuale, per le ultime 72 ore, andamento stagionale e <strong>previsioni a 7 giorni</strong>;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    mostrare <strong>indici di rischio in relazione ai dati meteo</strong>, alle <strong>fasi fenologiche</strong> della pianta e alle principali <strong>malattie fungine</strong>;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>verificare l’applicabilità</strong> del prodotto in base alle condizioni meteo;
                  </li>

                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>valutare il livello di protezione</strong> (efficacia e persistenza) della coltura raggiunto con i trattamenti eseguiti in relazione al grafico del meteo e al livello di rischio della malattia;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    fornire <strong>alert tempestivi</strong> sul rischio di infezione delle principali malattie e insetti, stress idrico e danni da gelo o alte temperature.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-0 pt-sm-0 pb-0 bg-primary">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-4">
              <img
                className="img-fluid"
                width="382"
                height="203"
                loading="lazy"
                src={SistemaDigitale}
                alt="Sistema Digitale"
              />
            </div>
            <div className="col-lg-8 col-md-8 mt-2 pt-2 mt-sm-0 pt-sm-0">
              <div className="text-white ms-lg-2">
                <p className="mb-2 mb-sm-0">
                Agrigenius<sup className="fs-7">®</sup> Vite è un <strong>sistema digitale di supporto alle decisioni</strong> che si basa su <strong>modelli previsionali</strong> sviluppati e <strong>testati sul territorio italiano da più di 10 anni</strong> e che tiene conto delle specifiche caratteristiche di <strong>più di 120 varietà di vite</strong>.

                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="bg-whitt">
        <div className="container px-2 px-sm-0">
          <div className="row">
          <div className="col-12">
            <div className="section-title ms-lg-4 mt-4">
            <div className="title mb-2 h4 text-secondary-green">
              Agrigenius<sup>®</sup> Vite ti permette di:
            </div>
          </div>
            </div>
          </div>
          <div className="row g-0 align-items-stretch">
            
            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon6}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Programmare gli interventi</strong> in base allo{" "}
                    <strong>sviluppo fenologico</strong> della pianta
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon5}
                    loading="lazy"
                    alt="Valutazione rischi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    Effettuare una{" "}
                    <strong>corretta valutazione dei rischi</strong> e
                    migliorare <strong>efficacia</strong> della strategia di
                    difesa
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon4}
                    loading="lazy"
                    alt="Registrare operazioni"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Registrare tutte le operazioni</strong> effettuate
                    in campo
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon3}
                    loading="lazy"
                    alt="Programmare gli interventi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Programmare interventi</strong> irrigui e fertirigui
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon2}
                    loading="lazy"
                    alt="Ricevere allerte"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    <strong>Ricevere allerte</strong> su potenziali danni da
                    gelo o alte temperature
                  </p>
                </div>
              </div>
            </div>

            <div className="col-xl-2 col-lg-4 col-md-4">
              <div className="card fea-primary text-center rounded-0 px-4 py-2 bg-white bg-gradient position-relative overflow-hidden border-0 h-100">
                <span className="h2 icon2 text-primary">
                  <img
                    className="img-fluid me-2"
                    width="40"
                    height="40"
                    src={Icon1}
                    loading="lazy"
                    alt="Impostare avvisi"
                  />
                </span>
                <div className="card-body p-0 content">
                  <p className="para mb-0">
                    Impostare <strong>avvisi di rischio infezione</strong> o{" "}
                    <strong>evoluzione insetti</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-2 pb-2 d-table w-100 parallax"
        style={{
          background: `url(${AgrigeniusVisual}) center center`,
          zIndex: 1,
        }}
        id="home"
        data-jarallax='{"speed": 0.5}'>
        <div className="bg-overlay-white"></div>
        <div className="container">
          <div className="row align-items-center mt-md-0">
            <div className="col-lg-7 col-md-6 order-1 order-md-1">
              <div className="title-heading mt-4">
                <div className="title mb-2 h4 text-secondary-green">
                  Perché scegliere Agrigenius<sup>®</sup> Vite?
                </div>
                <p className="para-dark">
                Agrigenius<sup className="fs-7">®</sup> Vite <strong>integra e supporta la tua esperienza tecnica</strong>,
                  aiutandoti a:
                </p>
                <ul className="list-unstyled">
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    avere{" "}
                    <strong>maggior consapevolezza sulle dinamiche</strong> che
                    interessano il vigneto;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                   <strong>prendere decisioni</strong> informate e attuare <strong>interventi tempestivi</strong>;

                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    anticipare i <strong>momenti critici per il controllo della malattia</strong>; 
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>ridurre i costi</strong> di gestione e lavorare in <strong>modo più sostenibile</strong>;
                  </li>
                  <li className="mb-0">
                    <span className="text-primary h5 me-2">
                      <BiCheckCircle
                        className="align-middle"
                        size={24}
                        color="#00793"
                      />
                    </span>
                    <strong>ottimizzare la produzione</strong> in termini di
                    qualità e sanità.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title mb-2 h4 text-secondary-green">
                Su misura per le tue esigenze
              </div>
              <p className="para-dark">
              Agrigenius<sup className="fs-7">®</sup> Vite è pensato per adattarsi alle necessità e agli obiettivi di grandi e piccole aziende ed è <strong>disponibile in 3 versioni</strong>:
              </p>
            </div>
            <div className="col-12">
              <div className="d-flex bg-secondary-green position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-secondary-green border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <p className="fs-4 mb-0 text-white text-center lh-sm">
                    PRO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      La tecnologia incontra l'esperienza tecnica
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  Una piattaforma web completa per la gestione professionale del tuo vigneto a 360°. Rielabora dati ambientali raccolti tramite stazione meteo in campo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-primary position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-primary border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  <p className="fs-4 mb-0 text-white text-center lh-sm">
                   GO
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      Per una visione semplice, tascabile ed immediata
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  App mobile per smartphone e tablet, dedicata alla difesa del vigneto. Riassume tutte le informazioni necessarie in modo intuitivo, si collega ad un punto meteo virtuale.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="d-flex bg-secondary-green position-relative py-4 mt-2 ms-5">
                <div className="box-Basf bg-secondary-green border-white position-absolute top-50 start-0 translate-middle align-self-sm-center align-self-start">
                  
                  <p className="fs-4 mb-0 text-white text-center lh-sm">
                    TECH
                  </p>
                </div>
                <div className="text-white pe-2 pe-sm-3 box-Basf-text">
                  <p className="mb-2 lh-sm h4">
                    <strong>
                      La soluzione per chi gestisce più realtà agricole
                    </strong>
                  </p>
                  <p className="mb-0 lh-sm">
                  Una piattaforma web che consente di monitorare più realtà produttive dislocate in areali lontani o con caratteristiche molto diverse tra loro.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5" id="form-mobile">
              <div className="card d-sm-none d-block shadow rounded border-0 me-lg-3">
                <div className="card-header bg-secondary-green no-border text-white">
                  <div className="lead fw-bold mb-0 text-center text-uppercase">
                    Preventivo gratuito
                  </div>
                </div>
                <div className="card-body z-3">
                  <Form
                    buttonClasses="btn-primary"
                    landingPageId="meteo"
                    initialTrackingImage="https://ad.doubleclick.net/ddm/activity/src=10044877;type=invmedia;cat=flbas008;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
                    trackingCode={`https://bqlgw3bxrpqrxgwqvdydj7xj2u0bqbtj.lambda-url.eu-central-1.on.aws/?uid=${uid}&page=basf2025_hp`}
                    uid={uid}  // Passa l'UID come prop
                    setUid={setUid}  // Passa la funzione per aggiornare l'UID
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <MobileButton buttonClasses="btn-primary" />
      <DesktopButton buttonClasses="btn-primary" />
      <BackToTopButton linkClasses="btn-primary" />
      <CookieBanner buttonVariant="primary" />
    </>
  );
};

export default HomeSection;
